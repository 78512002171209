import React from "react";
import jinxStanding from "../assets/jinx_standing.png";
import "../App.css";

const Home = () => {
  return (
    <div className="container">
      <section className="landing">
        <h1>JINX</h1>
        <p>
          A wicked little trickster.
          <br />
          A mind sharper than his claws.
          <br />
          Ruling over the trenches with a grin.
          <br />
          Prince of the Shadows.
          <br />
          Ruler of chaos.
        </p>
        <h3>CA: B4eppPm1Dj5Nd7KsN1cdJfQgWSSqqSfi9BsNZYkUkXm2</h3>
        <img src={jinxStanding} className="jinx_standing" />
      </section>
      <p>jinxthecoin@gmail.com</p>
    </div>
  );
};

export default Home;
