import React from "react";
import { Route, Routes } from "react-router-dom";
import MemeMaker from "./components/MemeMaker";
import Home from "./components/Home"; // Create this component

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/meme-maker" element={<MemeMaker />} />
    </Routes>
  );
};

export default AppRoutes;
